import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Background from "../assets/img/homepage/parallax-bg.png"

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  section: {
    backgroundImage: "url(" + Background + ")",
    backgroundSize:'cover',
    backgroundPosition: 'center center',
    backgroundRepeat:'no-repeat',
    padding: theme.spacing(3,3,3,3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6,3,6,3),
    },
  },
  box:{
    height: '240px',
    [theme.breakpoints.up('sm')]: {
      height: '320px',
    },
    [theme.breakpoints.up('md')]: {
      height: '400px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '480px',
    },
  },
  title:{
    margin: '0px 0px 8px 0px',
    [theme.breakpoints.up('md')]: {
      margin: '0px 0px 16px 0px',
    },
  },
  sub:{
    margin: '0px 0px 0px 0px',
    [theme.breakpoints.up('md')]: {
      margin: '0px 0px 8px 0px',
    },
  },
  body:{
    margin: '0px 0px 0px 0px',
  },
}))

export default function FullwidthHeader ( props ) {
  const classes = useStyles();

  return (
    <Container maxWidth="false" className={classes.section}>
      <Grid container xs={12} className={classes.box} direction="column" justify="center" alignItems="center">
        <Grid item className={classes.title} xs={'auto'}>
          <Typography variant="h3" align='center' style={{ fontWeight:'normal', color:'#fff' }}>
            LIHAT HAL BARU DI
          </Typography>
        </Grid>
        <Grid item className={classes.sub} xs={'auto'}>
          <Typography variant="h3" align='center' style={{ fontWeight:'bold', color:'#fff' }}>
            MY TRIP MY STYLE
          </Typography>
        </Grid>
        <Grid item className={classes.body} xs={'auto'}>
          <Typography variant="h6" align='center' style={{ fontWeight:'normal', color:'#fff' }}>
            Petualangan baru Anda selanjutnya
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}
  
FullwidthHeader.propTypes = {
  siteTitle: PropTypes.string,
}

FullwidthHeader.defaultProps = {
  siteTitle: ``,
}