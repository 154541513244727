import PropTypes from 'prop-types'
import React from 'react'


import { makeStyles, createStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => createStyles({
  subSection:{
    padding: theme.spacing(0,0,0,0),
  },
}));

export default function DividerSection ( props ) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid container xs={12} className={classes.section} direction="row" justify="center" alignItems="center">
        <Grid xs={12} direction="column" >
          <Divider variant="middle" />
        </Grid>
      </Grid>
    </Container>
  )
}
  
DividerSection.propTypes = {
  siteTitle: PropTypes.string,
}

DividerSection.defaultProps = {
  siteTitle: ``,
}