import PropTypes from 'prop-types'
import React from 'react'
import withWidth from '@material-ui/core/withWidth';

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Honeymoon from "../assets/img/homepage/honeymoon.png"
import Merapi from "../assets/img/homepage/gunung-merapi.png"
import Pesantren from "../assets/img/homepage/pesantren-malang.png"
import Virtual from "../assets/img/homepage/virtual-traveling.png"

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';

const article = [
  {
    id: '1',
    img: Honeymoon,
    title: 'Destinasi wisata yang cocok untuk pasangan honeymoon',
    caption: 'Artikel - Indonesia Tourism',
    author: 'author',
  },
  {
    id: '2',
    img: Merapi,
    title: 'Menikmati keindahan Gunung Merapi',
    caption: 'Artikel - Indonesia Tourism',
    author: 'author',
  },
  {
    id: '3',
    img: Pesantren,
    title: 'Pesantren dengan masjid indah di kota Malang',
    caption: 'Artikel - Indonesia Tourism',
    author: 'author',
  },
  {
    id: '4',
    img: Virtual,
    title: 'Virtual Travelling during Covid19',
    caption: 'Artikel - Indonesia Tourism',
    author: 'author',
  },
];

function Article ( props ) {
  const shadowStyles = useSoftRiseShadowStyles();
  const { width } = props;

  return (
    <Grid container justify="center" alignItems="center" spacing={2}>
      {article.map((node) => (
        <Grid item xs={6} md={4} lg={3}>
          <Card className={shadowStyles.root}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt={node.title}
                height={(width === 'xs') || (width === 'sm') ? '150': '200'}
                image={node.img}
                title={node.title}
                style={{margin:'0px 0px 0px 0px'}}
              />
              <CardContent style={{ justifyContent:"space-between", height: (width === 'xs') || (width === 'sm') ? '75px': '100px', padding: '8px 8px 8px 8px' }}>
                <Typography noWrap variant="body1" style={{ fontWeight:'bold'}}>
                  {node.title}
                </Typography>
                <Typography noWrap variant="caption" color="textSecondary">
                  {node.caption}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
              
Article.propTypes = {
  siteTitle: PropTypes.string,
}

Article.defaultProps = {
  siteTitle: ``,
}

Article.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Article);