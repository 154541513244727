import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import AirAsia from "../assets/img/partnership/air-asia.png"
import Kirana from "../assets/img/partnership/kirana.png"
import PergiRameRame from "../assets/img/partnership/pergi-rame-rame.png"

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const article = [
  {
    id: '1',
    img: AirAsia,
    caption: 'Air Asia',
  },
  {
    id: '2',
    img: Kirana,
    caption: 'Kirana',
  },
  {
    id: '3',
    img: PergiRameRame,
    caption: 'Pergi Rame Rame',
  },
];

const useStyles = makeStyles(theme => ({
  section:{
    padding: theme.spacing(3,3,3,3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6,3,6,3),
    }
  },
  textSection:{
    padding: theme.spacing(0,0,2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0,0,3),
    }
  },
  logoList:{
    margin: '16px 0px 16px 0px',
    [theme.breakpoints.up('md')]: {
      margin: '24px 0px 24px 0px',
    }
  },
  logo:{
    width:'75px',
    margin: '0px 16px 0px 16px',
    [theme.breakpoints.up('md')]: {
      width:'100px',
      margin: '0px 24px 0px 24px',
    }
  },
}))

export default function Partnership ( props ) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.section}>
      <Grid container xs={12} direction="row" justify="center" alignItems="center">
        <Grid item className={classes.textSection} xs={12}>
          <Typography gutterBottom variant="h4" align='center' style={{ fontWeight:'bolder'}}>
            PARTNERSHIP
          </Typography>
          <Typography variant="body1" align='center' style={{color: '#8E8E8E'}}>
            My Trip My Style bekerja sama dengan beberapa brand besar untuk memberikan anda promo ekslusif
          </Typography>
        </Grid>
        <Grid item className={classes.logoList} align='center' xs={'auto'}>
          {article.map((node) => (
            <img className={classes.logo} src={node.img} alt={node.caption}/>
          ))}
        </Grid>
      </Grid>
    </Container>
  )
}
  
Partnership.propTypes = {
  siteTitle: PropTypes.string,
}

Partnership.defaultProps = {
  siteTitle: ``,
}