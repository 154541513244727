import React from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';

import Layout from '../components/layout'
import FullwidthHeader from '../components/fullwidthHeader'
import Partnership from '../components/partnership'
import Article from '../components/article'
import DividerSection from '../components/divider'
import SEO from '../components/seo'
import Banner from "../assets/img/homepage/jelajah-hal-seru.png"

import Bromo from "../assets/img/homepage/bromo-romantis.png"
import PantaiPink from "../assets/img/homepage/pantai-pink-lombok.png"
import Derawan from "../assets/img/homepage/pulau-derawan.png"
import Semeru from "../assets/img/homepage/bromo-tengger-semeru.png"
import Bajo from "../assets/img/homepage/labuan-bajo.png"
import Kenjeran from "../assets/img/homepage/pantai-kenjeran.png"
import Bali from "../assets/img/homepage/bali-with-love.png"
import Japan from "../assets/img/homepage/epic-japan.png"
import Singapore from "../assets/img/homepage/singapore-fantastic-trip.png"
import Eropa from "../assets/img/homepage/paket-wisata-murah-eropa.png"
import Israel from "../assets/img/homepage/wisata-rohani-israel.png"
import Bangkok from "../assets/img/homepage/murah-wisata-bangkok.png"
import Jakarta from "../assets/img/homepage/menikmati-macet-jakarta.png"

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles'
import { Container, Grid, Box } from '@material-ui/core/'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';

const favouriteDestination = [
  {
    id: '1',
    img: PantaiPink,
    title: 'Pantai Pink Lombok',
    caption: 'Indonesia Tourism',
    author: 'Pergi Rame Rame',
  },
  {
    id: '2',
    img: Derawan,
    title: 'Pulau Derawan',
    caption: 'Indonesia Tourism',
    author: 'Pergi Rame Rame',
  },
  {
    id: '3',
    img: Semeru,
    title: 'TN. Bromo Tengger Semeru',
    caption: 'Indonesia Tourism',
    author: 'Pergi Rame Rame',
  },
  {
    id: '4',
    img: Bajo,
    title: 'Labuan Bajo',
    caption: 'Indonesia Tourism',
    author: 'Pergi Rame Rame',
  },
  {
    id: '5',
    img: Kenjeran,
    title: 'Pantai Kenjeran',
    caption: 'Indonesia Tourism',
    author: 'Pergi Rame Rame',
  },
];

const recomendedTrip = [
  {
    id: '1',
    img: Bromo,
    title: 'Bromo Romantis',
    agent: 'Kirana Tour & Travel',
    rating: '3.5',
    price: '500.000',
    discount: '499.000',
    availableDate: '19 Juni 2020',
    wishlist: true
  },
  {
    id: '2',
    img: Bali,
    title: 'Bali with Love',
    agent: 'Kirana Tour & Travel',
    rating: '4.5',
    price: '1.500.000',
    discount: null,
    availableDate: '19 Juni 2020',
    wishlist: false
  },
  {
    id: '3',
    img: Japan,
    title: 'Epic Japan',
    agent: 'Kirana Tour & Travel',
    rating: '3',
    price: '8.500.000',
    discount: null,
    availableDate: '19 Juni 2020',
    wishlist: false
  },
  {
    id: '4',
    img: Singapore,
    title: 'Singapore Fantastic Trip',
    agent: 'Kirana Tour & Travel',
    rating: '4.5',
    price: '4.000.000',
    discount: '3.999.000',
    availableDate: '19 Juni 2020',
    wishlist: true
  },
  {
    id: '5',
    img: Eropa,
    title: 'Paket Wisata Eropa Murah',
    agent: 'Kirana Tour & Travel',
    rating: '4',
    price: '35.000.000',
    discount: null,
    availableDate: '19 Juni 2020',
    wishlist: false
  },
  {
    id: '6',
    img: Israel,
    title: 'Wisata Rohani Israel',
    agent: 'Kirana Tour & Travel',
    rating: '4',
    price: '57.000.000',
    discount: '55.000.000',
    availableDate: '19 Juni 2020',
    wishlist: true
  },
  {
    id: '7',
    img: Bangkok,
    title: 'Murah Wisata Bangkok',
    agent: 'Kirana Tour & Travel',
    rating: '4.5',
    price: '8.000.000',
    discount: null,
    availableDate: '19 Juni 2020',
    wishlist: false
  },
  {
    id: '8',
    img: Jakarta,
    title: 'Menikmati Macet Jakarta',
    agent: 'Kirana Tour & Travel',
    rating: '3.5',
    price: '6.000.000',
    discount: '2.999.000',
    availableDate: '19 Juni 2020',
    wishlist: true
  },
];

const useStyles = makeStyles(theme => createStyles({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: "#eee",
    paddingTop:'0px',
    marginTop:'0px',
  },
  section: {
    padding: theme.spacing(3,3,3,3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6,3,6,3),
    },
    '&:first-child': {
      padding: theme.spacing(6,3,3,3),
    },
  },
  textSection:{
    padding: theme.spacing(0,0,2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0,0,3),
    }
  },
  jumbotron: {
    backgroundColor: "#FFD300",
  },
  image: {
    // backgroundImage: url('images/bg.jpg'),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100px',
  },
  titleBar: {
    background: theme.palette.common.white, 
    height:'100px',
    alignItems: 'flex-start',
    padding: '8px 0px 8px 0px',
  },
  titleBarTop: {
    padding: '100px 15% 100px 15%',
    color: '#fff',
    position: 'absolute',
    top: 0,
    height: '100%',
    display: 'block'
  },
  listGrid: {
    margin: '0px 0px 0px 0px ',
    padding: '0px 0px 0px 0px',
  },
  tileList: {
    background: theme.palette.common.white,
    margin: '0px 0px 0px 0px ',
    padding: '0px 0px 0px 0px',
    boxShadow: theme.shadows[1],
  },
  blockContainer: {
    margin: '0px 0px 0px 0px ',
    padding: '0px 0px 0px 0px',
    position: 'relative',
    display: 'block',
    // In case it's not rendered with a div.
    height: '100%',
    overflow: 'hidden',
  },
  cardContent: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
}));

function IndexPage(props) {
  const classes = useStyles();
  const shadowStyles = useSoftRiseShadowStyles();
  const theme = useTheme();
  const { width } = props;

  return(
    <Layout>
      <SEO title="Home" />
      <Grid container component="main" className={classes.root}>

        {/* Section Destinasi Favorit */}
        <Container maxWidth="lg" className={classes.section}>
          <Grid container xs={12} direction="row" justify="center" alignItems="center">
            <Grid item className={classes.textSection} xs={12}>
              <Typography gutterBottom variant="h4" align='center' style={{ fontWeight:'bold'}}>
                DESTINASI FAVORIT
              </Typography>
              <Typography variant="body1" align='center' style={{color: '#8E8E8E'}}>
                Destinasi paling disukai para traveller
              </Typography>
            </Grid>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} lg={8} className={classes.blockContainer}>
                <Card>
                  <CardMedia
                    component="img"
                    alt='banner image'
                    height= '450'
                    image={Banner}
                    title='banner'
                    style={{margin:'0px 0px 0px 0px', backgroundSize: 'contain'}}
                  />
                  <Grid container xs={12} className={classes.titleBarTop} justify='center' alignItems='center'>
                      <Grid item xs={'auto'} lg={12} style={{margin:'5% 0px 5% 0px'}}>
                        <Typography gutterBottom variant="h4" align='center' style={{ fontWeight:'bolder'}}>
                          Jelajah Hal Seru
                        </Typography>
                        <Typography variant="body1" align='center'>
                          Pemandangan indah puncak gunung, pantai dengan laut biru,
                          dan keindahan kota saat malam dapat anda nikmati Bersama My Trip My Style
                        </Typography>
                      </Grid>
                      <Grid item xs={12} align='center'>
                        <Button variant="outlined" style={{color: '#fff', borderColor: '#fff'}}>Jelajahi</Button>
                      </Grid>
                    </Grid>
                </Card>
              </Grid>
              {favouriteDestination.map((node) => (
                (width === 'xs' || width === 'sm') ? (
                  node.id <= '2' ? (
                <Grid item xs={12} sm={6} lg={node.id === '1'? 4:3} style={{width:'386px'}}>
                  <Card className={(shadowStyles.root)}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt={node.caption}
                        height= {node.id ==='1' ? ((width === 'lg') || (width === 'xl') ? '350': '200') : '200'}
                        image={node.img}
                        title={node.title}
                        style={{margin:'0px 0px 0px 0px', backgroundSize: 'contain'}}
                      />
                      <CardContent alignItems= "flex-start"  style={{height:'100px', padding:'8px 8px 8px 8px'}}>
                        <Typography noWrap variant="body1" style={{ fontWeight:'bold',fontSize:'16px' }}>
                          {node.title}
                        </Typography>
                        <Typography noWrap variant="body2" color="textSecondary" style={{ fontSize:'10px'}}>
                          {node.caption}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid> ):(null)
                ):(
                <Grid item md={4} lg={node.id === '1'? 4:3} style={{width:'386px'}}>
                  <Card className={(shadowStyles.root)}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt={node.caption}
                        height= {node.id ==='1' ? ((width === 'lg') || (width === 'xl') ? '350': '200') : '200'}
                        image={node.img}
                        title={node.title}
                        style={{margin:'0px 0px 0px 0px', backgroundSize: 'contain'}}
                      />
                      <CardContent alignItems= "flex-start"  style={{height:'100px', padding:'8px 8px 8px 8px'}}>
                        <Typography noWrap variant="body1" style={{ fontWeight:'bold',fontSize:'16px' }}>
                          {node.title}
                        </Typography>
                        <Typography noWrap variant="body2" color="textSecondary" style={{ fontSize:'10px'}}>
                          {node.caption}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                )
              ))}
            </Grid>
          </Grid>
        </Container>

        {/* Section Rekomendasi Perjalanan */}
        <Container maxWidth="lg" className={classes.section}>
          <Grid container xs={12} direction="row" justify="center" alignItems="center">
            <Grid item className={classes.textSection} xs={12}>
              <Typography gutterBottom variant="h4" align='center' style={{ fontWeight:'bolder'}}>
                REKOMENDASI PERJALANAN
              </Typography>
              <Typography variant="body1" align='center' style={{color: '#8E8E8E'}}>
                Perjalanan Khusus pilihan kami untuk Anda
              </Typography>
            </Grid>
            <Grid container justify="center" alignItems="center" spacing={2}>
              {recomendedTrip.map((node) => (
                // width:(width === 'xs') || (width === 'sm') ? '150px':
                <Grid item xs={6} md={4} lg={3}>
                  <Card className={shadowStyles.root}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt={node.caption}
                        height={(width === 'xs') || (width === 'sm') ? '150': '200'}
                        image={node.img}
                        title={node.title}
                        style={{margin:'0px 0px 0px 0px'}}
                      />
                      </CardActionArea>
                      <CardContent style={{height: (width === 'xs') || (width === 'sm') ? '75px': '100px', padding:(width === 'xs')||(width === 'sm') ?(4,4,4,4):(8,8,8,8), position:'relative'}}>
                        <IconButton aria-label="add to favorites" style={{ position:'absolute', padding:(width === 'xs') || (width === 'sm') ?2:4 ,top: (width === 'xs') ? 4:8, right:(width === 'xs') ? 4:8}}>
                          <FavoriteIcon style={{ color: node.wishlist?'#ff6d75':'#dadada' ,fontSize: (width === 'xs')||(width === 'sm') ?theme.typography.pxToRem(12):theme.typography.pxToRem(18) }} />
                        </IconButton>
                        <Typography noWrap variant="body1" style={{ marginRight:'32px', fontWeight:'bold',fontSize:(width === 'xs')?'12px':(width === 'sm')?'14px':'16px'}}>
                          {node.title}
                        </Typography>
                        <Typography noWrap variant="body2" color="textSecondary" style={{ color:'#9a9a9a', fontSize:'10px'}}>
                          {node.agent}
                        </Typography>
                        <Box borderColor="transparent">
                          <Rating
                            readOnly
                            name="content-rate"
                            value={node.rating}
                            precision={0.5}
                            emptyIcon={<StarBorderIcon style={{ fontSize: (width === 'xs') ? theme.typography.pxToRem(8) : theme.typography.pxToRem(12) }} />}
                            style={{ fontSize: (width === 'xs') ? theme.typography.pxToRem(8) : theme.typography.pxToRem(12) }}
                          />
                        </Box>
                        {node.discount? (
                          <Box style={{ position:'absolute' ,bottom: (width === 'xs')||(width === 'sm')?16:18, right:(width === 'xs') ? 4:8}}>
                            <Typography noWrap align='right' variant="body1" style={{color:'#8e8e8e',fontWeight:'bold',fontSize:'8px'}}>
                              <del>IDR {node.price}</del>
                            </Typography>
                            <Typography noWrap align='right' variant="body1" style={{fontWeight:'bold',fontSize:(width === 'xs')||(width === 'sm')?'12px':'16px'}}>
                              IDR {node.discount}
                            </Typography>
                          </Box>
                        ):(
                          <Box style={{ position:'absolute' ,bottom: (width === 'xs')||(width === 'sm')?16:18, right:(width === 'xs') ? 4:8}}>
                            <Typography noWrap align='right' variant="body1" style={{fontWeight:'bold',fontSize:'8px'}} />
                            <Typography noWrap align='right' variant="body1" style={{fontWeight:'bold',fontSize:(width === 'xs')||(width === 'sm')?'12px':'16px'}}>
                              IDR {node.price}
                            </Typography>
                          </Box>
                        )}
                        <Typography noWrap variant={(width === 'xs')||(width === 'sm')?'body2':'caption'} style={{color:'#9a9a9a', fontSize:'8px'}}>
                          Tersedia dari tanggal {node.availableDate}
                        </Typography>
                      </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>

        {/* Section Fullwidth Header #1 */}
        <FullwidthHeader />

        {/* Section Destinasi Favorit */}
        <Container maxWidth="lg" className={classes.section}>
          <Grid container xs={12} direction="row" justify="center" alignItems="center">
            <Article />
          </Grid>
        </Container>

        <DividerSection />

        {/* Section Partner */}
        <Partnership />

      </Grid>
    </Layout>
  )
}

IndexPage.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(IndexPage);